'use client';
import { ImageType } from '@/types/Image';

import { CompanyAndMission } from '../company-and-mission/CompanyAndMission.component';

import styles from './CompanySection.module.scss';

type CompanySectionProps = {
    companyStrings: {
        title: string;
        subtitle: string;
        body: string;
    };
    missionStrings: {
        title: string;
        subtitle: string;
        body: string;
    };
    missionImage: ImageType;
    companyImage: ImageType;
    learnText: string;
};

export const CompanySection = ({
    companyStrings,
    missionStrings,
    companyImage,
    missionImage,
    learnText,
}: CompanySectionProps) => {
    return (
        <section className={styles.companySection}>
            <CompanyAndMission
                strings={companyStrings}
                learnText={learnText}
                image={companyImage}
            />
            <CompanyAndMission
                strings={missionStrings}
                learnText={learnText}
                image={missionImage}
            />
        </section>
    );
};
