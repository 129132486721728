'use client';
import Image from 'next/image';
import { useRef } from 'react';

import Bg from '@/assets/images/line-bg.png';
import { useLanguageStore } from '@/features/site-language/store/language-store-provider';
import { useInView } from '@/hooks/useInView';
import { ImageType } from '@/types/Image';

import { Button } from '../button/Button.component';
import { TextBlock } from '../text-block/TextBlock.component';

import styles from './CompanyAndMission.module.scss';

type CompanyAndMissionProps = {
    strings: {
        title: string;
        subtitle: string;
        body: string;
    };
    image: ImageType;
    learnText: string;
};

export const CompanyAndMission = ({ strings, image, learnText }: CompanyAndMissionProps) => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { inView } = useInView({ ref: containerRef, onlyOnce: true });
    const { language } = useLanguageStore((state) => state);

    return (
        <div
            className={`${styles.companyAndMissionUnit} ${inView ? styles.animate : ''}`}
            ref={containerRef}
        >
            <Image
                className={styles.initialImage}
                alt=""
                src={image?.src}
                width={image?.width}
                height={image?.height}
            />
            <div className={styles.copy}>
                <Image className={styles.bgImage} alt="" src={Bg} fill />
                <h3>{strings.title}</h3>
                <h5>{strings.subtitle}</h5>
                <TextBlock content={strings.body} />
                <Button href={`${language.path}/404`} kind="secondary" extraClass={styles.joinBtn}>
                    {learnText}
                    <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" />
                    </svg>
                </Button>
            </div>
        </div>
    );
};
