'use client';

import clsx from 'clsx';
import { useRef } from 'react';

import { Button } from '@/components/button/Button.component';
import { useLanguageStore } from '@/features/site-language/store/language-store-provider';
import { useInView } from '@/hooks/useInView';

import { TextBlock } from '../text-block/TextBlock.component';

import styles from './JobsSection.module.scss';

type JobsSectionProps = {
    featuredStrings: {
        title: string;
        introduction: string;
    };
    jobStrings: {
        title_label: string;
        title: string;
        description_label: string;
        description: string;
        type_label: string;
        type: string;
        openings_label: string;
        openings: string;
    };
    applyText: string;
};

export const JobsSection = ({ featuredStrings, jobStrings, applyText }: JobsSectionProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { inView } = useInView({ ref: containerRef, onlyOnce: true });
    const { language } = useLanguageStore((state) => state);

    return (
        <section
            className={clsx(styles.jobsSection, { [styles.animate]: inView })}
            ref={containerRef}
        >
            <div className={styles.featured}>
                <h3>{featuredStrings.title}</h3>
                <TextBlock className={styles.intro} content={featuredStrings.introduction} />
            </div>
            <div className={styles.listing}>
                <div className={styles.title}>
                    <div className={styles.label}>{jobStrings.title_label}</div>
                    <>
                        <h4>{jobStrings.title}</h4>
                    </>
                </div>
                <div className={styles.description}>
                    <div className={styles.label}>{jobStrings.description_label}</div>
                    <TextBlock content={jobStrings.description} />
                </div>
                <div className={styles.type}>
                    <div>
                        <div className={styles.label}>{jobStrings.type_label}</div>
                        <p>{jobStrings.type}</p>
                    </div>
                    <div>
                        <div className={styles.label}>{jobStrings.openings_label}</div>
                        <p>{jobStrings.openings}</p>
                    </div>
                </div>
                <div className={styles.apply}>
                    <Button
                        href={`${language.path}/careers`}
                        kind="secondary"
                        extraClass={styles.joinBtn}
                    >
                        {applyText}
                        <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path d="M12 4L10.59 5.41L16.17 11H4V13H16.17L10.59 18.59L12 20L20 12L12 4Z" />
                        </svg>
                    </Button>
                </div>
            </div>
        </section>
    );
};
