'use client';
import clsx from 'clsx';
import { EmblaCarouselType } from 'embla-carousel';
import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';

import Triangle from '@/assets/images/simple-arrow-down.svg';

import styles from './ArrowButtons.module.scss';

type UsePrevNextButtonsType = {
    prevBtnDisabled: boolean;
    nextBtnDisabled: boolean;
    onPrevButtonClick: () => void;
    onNextButtonClick: () => void;
};

export const usePrevNextButtons = (
    emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType => {
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

    const onPrevButtonClick = useCallback(() => {
        if (!emblaApi) return;
        emblaApi.scrollPrev();
    }, [emblaApi]);

    const onNextButtonClick = useCallback(() => {
        if (!emblaApi) return;
        emblaApi.scrollNext();
    }, [emblaApi]);

    const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
        setPrevBtnDisabled(!emblaApi.canScrollPrev());
        setNextBtnDisabled(!emblaApi.canScrollNext());
    }, []);

    useEffect(() => {
        if (!emblaApi) return;

        onSelect(emblaApi);
        emblaApi.on('reInit', onSelect);
        emblaApi.on('select', onSelect);
    }, [emblaApi, onSelect]);

    return {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick,
    };
};

interface PropType
    extends PropsWithChildren,
        React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {}

export const PrevButton = (props: PropType) => {
    const { className, children, ...restProps } = props;

    return (
        <button
            className={clsx(className, styles.button, styles.prev)}
            type="button"
            {...restProps}
        >
            <Triangle />
            {children}
        </button>
    );
};

export const NextButton = (props: PropType) => {
    const { className, children, ...restProps } = props;

    return (
        <button
            className={clsx(className, styles.button, styles.next)}
            type="button"
            {...restProps}
        >
            <Triangle />
            {children}
        </button>
    );
};
