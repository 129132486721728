'use client';
import { TextBlock } from '@/components/text-block/TextBlock.component';

import styles from './Testimonial.module.scss';

type TestimonialProps = {
    copy: string;
};

export const Testimonial = ({ copy }: TestimonialProps) => {
    return (
        <div className={styles.testimonial}>
            <div className={styles.copy}>
                <svg
                    className={styles.stars}
                    viewBox="0 0 93 17"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M3.838 16.7585L5.871 10.7165L0.969 7.42955H6.973L9.12 0.950546L11.305 7.42955H17.271L12.369 10.7165L14.44 16.7585L9.12 12.9015L3.838 16.7585ZM22.6473 16.7585L24.6803 10.7165L19.7783 7.42955H25.7823L27.9293 0.950546L30.1143 7.42955H36.0803L31.1783 10.7165L33.2493 16.7585L27.9293 12.9015L22.6473 16.7585ZM41.4565 16.7585L43.4895 10.7165L38.5875 7.42955H44.5915L46.7385 0.950546L48.9235 7.42955H54.8895L49.9875 10.7165L52.0585 16.7585L46.7385 12.9015L41.4565 16.7585ZM60.2658 16.7585L62.2988 10.7165L57.3968 7.42955H63.4008L65.5478 0.950546L67.7328 7.42955H73.6988L68.7968 10.7165L70.8678 16.7585L65.5478 12.9015L60.2658 16.7585ZM79.075 16.7585L81.108 10.7165L76.206 7.42955H82.21L84.357 0.950546L86.542 7.42955H92.508L87.606 10.7165L89.677 16.7585L84.357 12.9015L79.075 16.7585Z" />
                </svg>
                <TextBlock content={copy} />
                <svg
                    className={styles.quote}
                    width="45"
                    height="32"
                    viewBox="0 0 45 32"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path d="M3.20336 32H12.8034L19.2034 19.2V0H0.00335693V19.2H9.60336L3.20336 32ZM28.8034 32H38.4034L44.8034 19.2V0H25.6034V19.2H35.2034L28.8034 32Z" />
                </svg>
            </div>
        </div>
    );
};
