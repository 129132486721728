'use client';
import clsx from 'clsx';
import Image from 'next/image';
import { useEffect, useRef, useState } from 'react';

import { Carousel } from '@/components/carousel/Carousel.component';
import { Testimonial } from '@/components/testimonial/Testimonial.component';
import { useInView } from '@/hooks/useInView';

import styles from './TestimonialsSection.module.scss';

type TestimonialSectionProps = {
    testimonials: any[];
};

export const TestimonialsSection = ({ testimonials }: TestimonialSectionProps) => {
    const [randomTestimonials, setRandomTestimonials] = useState<any[]>([]);
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { inView } = useInView({ ref: containerRef, onlyOnce: true });

    useEffect(() => {
        let i = 0;
        const temp = [];
        const usedQuotes: number[] = [];

        const randomImage = Math.floor(Math.random() * (testimonials?.length - 1));

        temp.push({
            image: testimonials[randomImage]?.image,
        });

        while (i < 3) {
            const randomQuote = Math.floor(Math.random() * (testimonials?.length - 1));
            if (!usedQuotes.includes(randomQuote)) {
                usedQuotes.push(randomQuote);
                temp.push({
                    copy: testimonials[randomQuote]?.quote,
                });
                i++;
            }
        }

        setRandomTestimonials(temp);
    }, [testimonials]);

    return (
        <section
            className={clsx(styles.testimonialsSection, { [styles.animate]: inView })}
            ref={containerRef}
        >
            <Carousel
                containerClass={styles.carouselContainer}
                slidesClass={styles.carousel}
                slideClass={styles.slide}
                align={'start'}
            >
                {randomTestimonials.map((testimonial: any, idx) =>
                    testimonial.copy ? <Testimonial key={idx} copy={testimonial.copy} /> : null
                )}
            </Carousel>
            {randomTestimonials[0]?.image && (
                <Image
                    className={styles.testimonialImage}
                    alt=""
                    src={randomTestimonials[0]?.image?.src}
                    width={randomTestimonials[0]?.image?.width}
                    height={randomTestimonials[0]?.image?.height}
                />
            )}
        </section>
    );
};
