'use client';
import clsx from 'clsx';
import { EmblaOptionsType } from 'embla-carousel';
import AutoScroll, { AutoScrollOptionsType } from 'embla-carousel-auto-scroll';
import Autoplay, { AutoplayOptionsType } from 'embla-carousel-autoplay';
import ClassNames from 'embla-carousel-class-names';
import useEmblaCarousel from 'embla-carousel-react';
import React, {
    Children,
    PropsWithChildren,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';

import { PrevButton, NextButton, usePrevNextButtons } from './ArrowButtons.component';
import styles from './Carousel.module.scss';
import { DotButton, useDotButton } from './DotButtons.component';

interface CarouselPropsType extends EmblaOptionsType {
    containerClass?: string;
    slidesClass?: string;
    slideClass?: string;
    activeClass?: string;
    inViewClass?: string;
    pagination?: boolean;
    navigation?: boolean;
    autoplay?: AutoplayOptionsType;
    autoscroll?: AutoScrollOptionsType;
    thumbnails?: React.ReactNode[];
    slideToClickedSlide?: boolean;
}

export const Carousel = ({
    containerClass,
    slidesClass,
    slideClass,
    activeClass,
    inViewClass,
    pagination = true,
    navigation = true,
    autoplay,
    autoscroll,
    thumbnails,
    slideToClickedSlide,
    children,
    // allow additional options for embla carousel
    ...props
}: PropsWithChildren<CarouselPropsType>) => {
    const { loop: initialLoop, slides: initialSlide } = props;
    const nav = useRef<HTMLDivElement | null>(null);

    const [selectedIndex, setSelectedIndex] = useState(0);
    const [showDots, setShowDots] = useState(pagination);
    const [loops, setLoops] = useState(false);

    const slides = Children.toArray(children) || initialSlide || [];

    const [emblaRef, emblaApi] = useEmblaCarousel(
        {
            ...props,
        },
        [ClassNames({ snapped: activeClass, inView: inViewClass })]
            .concat(autoplay ? [Autoplay(autoplay)] : [])
            .concat(autoscroll ? AutoScroll(autoscroll) : [])
    );

    const { scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

    const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
        usePrevNextButtons(emblaApi);

    const handleSlideClick = (index: number) => {
        if (!slideToClickedSlide) return;
        emblaApi?.scrollTo(index);
    };

    // Thumbnails
    const [thumbsRef, thumbsApi] = useEmblaCarousel({
        containScroll: 'keepSnaps',
        dragFree: true,
    });

    const onThumbClick = useCallback(
        (index: number) => {
            if (!emblaApi || !thumbsApi) return;
            emblaApi.scrollTo(index);
        },
        [emblaApi, thumbsApi]
    );

    const onSelect = useCallback(() => {
        if (!emblaApi) return;
        setSelectedIndex(emblaApi.selectedScrollSnap());

        if (!thumbsApi) return;
        thumbsApi.scrollTo(emblaApi.selectedScrollSnap());
    }, [emblaApi, thumbsApi, setSelectedIndex]);

    useEffect(() => {
        if (!emblaApi) return;
        onSelect();
        emblaApi.on('select', onSelect);
        emblaApi.on('reInit', onSelect);
    }, [emblaApi, onSelect]);

    useEffect(() => {
        if (!emblaApi) return;

        if (scrollSnaps.length < 2) {
            setShowDots(false);
        } else if (pagination) {
            setShowDots(true);
        }
    }, [emblaApi, scrollSnaps, setShowDots, pagination]);

    useEffect(() => {
        const observer = new ResizeObserver(() => {
            if (nav.current) {
                const width = nav.current.offsetWidth;
                const scroll = nav.current.scrollWidth;

                setShowDots((showDots) => showDots && scroll > width);
            }
        });

        nav.current && observer.observe(nav.current);

        return () => observer.disconnect();
    }, [nav]);

    useEffect(() => {
        if (!emblaApi || !initialLoop || loops) return;

        const doesLoop = Boolean(emblaApi?.canScrollPrev());
        setLoops(doesLoop);
    }, [emblaApi, initialLoop, loops]);

    return (
        <div className={clsx(containerClass, initialLoop && loops && styles.loops)}>
            <div className={styles.viewport} ref={emblaRef}>
                <div className={clsx(styles.slidesContainer, slidesClass)}>
                    {slides.map((slide: any, index) => (
                        <button
                            key={index}
                            className={clsx(styles.slide, slideClass)}
                            onClick={() => handleSlideClick(index)}
                            data-testid={`slide-${index + 1}`}
                        >
                            <div
                                className={clsx(styles.innerSlide, {
                                    [styles.clickable]:
                                        slideToClickedSlide && index !== selectedIndex,
                                })}
                            >
                                {slide}
                            </div>
                        </button>
                    ))}
                </div>
            </div>
            <div className={styles.controls} ref={nav}>
                {navigation && (
                    <>
                        <PrevButton
                            className={styles.prev}
                            onClick={onPrevButtonClick}
                            disabled={prevBtnDisabled}
                            data-testid={'prev-button'}
                        />
                        <NextButton
                            className={styles.next}
                            onClick={onNextButtonClick}
                            disabled={nextBtnDisabled}
                            data-testid={'next-button'}
                        />
                    </>
                )}
                {thumbnails && (
                    <div className={styles.thumbs}>
                        <div className={styles.thumbsViewport} ref={thumbsRef}>
                            <ul className={styles.thumbsContainer} data-testid={'thumbnail'}>
                                {thumbnails.map((thumbnail, index) => (
                                    <li
                                        key={index}
                                        className={clsx(styles.thumbnail, {
                                            [styles.thumbnailSelected]: index === selectedIndex,
                                        })}
                                    >
                                        <button onClick={() => onThumbClick(index)}>
                                            {thumbnail}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                )}
                {showDots && !thumbnails && (
                    <div className={styles.dots}>
                        {scrollSnaps.map((_, index) => (
                            <DotButton
                                key={index}
                                className={clsx(styles.dot, {
                                    [styles.selected]: index === selectedIndex,
                                })}
                                onClick={() => onDotButtonClick(index)}
                            />
                        ))}
                    </div>
                )}
            </div>
        </div>
    );
};
