'use client';
import clsx from 'clsx';
import Image from 'next/image';
import { useRef } from 'react';

import Bg from '@/assets/images/line-bg.png';
import { CultureType } from '@/features/strapi/types';
import { useInView } from '@/hooks/useInView';

import { TextBlock } from '../text-block/TextBlock.component';

import styles from './OurCulture.module.scss';

const Article = ({ content }: { content: CultureType }) => {
    return (
        <div
            className={clsx(styles.articleWrapper, {
                [styles.onlyDescription]: !content.title && !content.subtitle,
            })}
        >
            <div className={styles.headline}>
                {content.title && <h3>{content.title}</h3>}
                {content.subtitle && <h5>{content.subtitle}</h5>}
            </div>
            {content.body && <TextBlock content={content.body} className={styles.bodyCopy} />}
        </div>
    );
};

export const OurCulture = ({ content }: { content: CultureType }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { inView } = useInView({ ref: containerRef, onlyOnce: true });

    return (
        <section className={clsx(styles.wrapper, { [styles.animate]: inView })} ref={containerRef}>
            <Image className={styles.bgImage} src={Bg} alt="" fill />
            <div className={styles.articles}>
                <Article content={content} />
            </div>
            {content.disclaimers && (
                <TextBlock content={content.disclaimers} className={styles.disclaimers} />
            )}
        </section>
    );
};
